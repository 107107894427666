import React from 'react';

const detalhesSemSoma = [
    'ivv',
    'oferta_final',
    'vgv_oferta_lancada',
    'preco_metro_medio',
    'preco_metro_minimo',
    'preco_metro_maximo',
    'area_privativa_minima',
    'area_privativa_media',
    'area_privativa_maxima',
    'preco_medio',
];

class TableRelatorioAcompanhamento extends React.Component{

    componentDidMount(){
        console.log(this.props);
    }

    formatNumber = (number) => {
        if (isNaN(number)) {
            number = 0;
        }
        return new Intl.NumberFormat('pt-BR', {maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number);
    }

    formatNumberAsPercentage = (number) => {
        if (isNaN(number)) {
            number = 0;
        }
        return new Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number);
    }

    formatPeriodString = (period) => {

        if( typeof period === 'number'){
            period = period.toString();
        }

        if( this.props.response_period_type === 'mensal'){
            let periodsParts = period.split('-');
            return `${periodsParts[1]}/${periodsParts[0]}`;
        }

        if( this.props.response_period_type === 'trimestre'){
            let periods = period.split('-');
            return `${periods[0]}T/${periods[1]}`;
        }

        if( this.props.response_period_type === 'semestre'){

            let periods = period.split('-');

            return `${periods[0]}S/${periods[1]}`;
        }

        if( this.props.response_period_type === 'anual'){
            return period;
        }

    }

    getDetailsColumns = () => {

        let columns = [];

        if( this.props.tipo_detalhamento === 'Padrão'){
            columns = ["Detalhe", "Tipo", "Padrão"];
        }

        if( this.props.tipo_detalhamento === 'Tipo'){
            columns = ["Detalhe", "Tipo"];
        }

        if( this.props.tipo_detalhamento === 'Bairro'){
            columns = ["Detalhe", "Tipo", "Cidade", "Bairro"];
        }

        if( this.props.tipo_detalhamento === 'Cidade'){
            columns = ["Detalhe", "Tipo", "Cidade"];
        }

        if( this.props.tipo_detalhamento === 'Estado'){
            columns = ["Detalhe", "Tipo", "Estado"];
        }

        if( this.props.tipo_detalhamento === 'Tipologia'){
            columns = ["Detalhe", "Tipologia"];
        }

        if( this.props.tipo_detalhamento === 'Incorporadora'){
            columns = ["Detalhe", "Tipo", "Incorporadora"];
        }

        if( this.props.tipo_detalhamento === 'Ano de Lançamento'){
            columns = ["Detalhe", "Ano de Lançamento"];
        }

        if( this.props.tipo_detalhamento === 'Cluster'){
            columns = ["Detalhe", "Tipo", "Cidade", "Cluster"];
        }

        if( this.props.tipo_detalhamento === 'Estágio de Obra e Padrão'){
            columns = ["Detalhe", "Tipo","Padrão", "Estágio de Obra"];
        }

        if( this.props.tipo_detalhamento === 'Estágio de Obra e Tipo'){
            columns = ["Detalhe", "Tipo", "Estágio de Obra"];
        }

        return columns;

    }

    formatTipologia = (tipologia) => {
        if( tipologia == 1){
            return `${tipologia} Quarto`;
        }

        if( tipologia == 4){
            return `${tipologia}+ Quartos`;
        }

        return `${tipologia} Quartos`;

    }

    getHeadersColumns = () => {

        let detailsColumns = this.getDetailsColumns();

        let periodsColumns = this.props.periodos.map((periodo, index)=>{
            return  this.formatPeriodString(periodo);
        });

        let columns = detailsColumns.concat(periodsColumns);

        columns.push("Total");

        return columns;

    }

    getBodyRows = () => {
        let rows = [];

        this.props.rows.map( (row, index ) =>{
            let newRow = [];

            let soma = 0;

            newRow.push(this.props.detalhesLabel[row.detalhe]);

            if( row.hasOwnProperty("tipo")){
                newRow.push(row.tipo);
            }

            if( row.hasOwnProperty("estagio")){
                newRow.push(row.estagio);
            }


            if( row.hasOwnProperty("ano_lancamento")){
                newRow.push(row.ano_lancamento);
            }

            if( row.hasOwnProperty("incorporadora")){
                newRow.push(row.incorporadora);
            }

            if( row.hasOwnProperty("tipologia")){

                newRow.push(row.tipologia);

            }

            if( row.hasOwnProperty("padrao")){
                newRow.push(row.padrao);
            }

            if( row.hasOwnProperty("cidade")){
                newRow.push(row.cidade);
            }

            if( row.hasOwnProperty("bairro")){
                newRow.push(row.bairro);
            }

            if( row.hasOwnProperty("cluster")){
                newRow.push(row.cluster);
            }

            this.props.periodos.map( (periodo, index) =>{
                soma += row[periodo];
                newRow.push(row[periodo]);
            });
            newRow.push(soma);
            rows.push(newRow);

            if( this.props.variacao !== '' && this.props.variacao != undefined){
                let variacaoRow = [];
                variacaoRow.push(`Variação - ${this.props.detalhesLabel[row.detalhe]}`);

                if( row.hasOwnProperty("tipo")){
                    variacaoRow.push(row.tipo);
                }

                if( row.hasOwnProperty("estagio")){
                    variacaoRow.push(row.estagio);
                }

                if( row.hasOwnProperty("ano_lancamento")){
                    variacaoRow.push(row.ano_lancamento);
                }

                if( row.hasOwnProperty("incorporadora")){
                    variacaoRow.push(row.incorporadora);
                }

                if( row.hasOwnProperty("tipologia")){

                    variacaoRow.push(row.tipologia);

                }

                if( row.hasOwnProperty("padrao")){
                    variacaoRow.push(row.padrao);
                }

                if( row.hasOwnProperty("cidade")){
                    variacaoRow.push(row.cidade);
                }

                if( row.hasOwnProperty("bairro")){
                    variacaoRow.push(row.bairro);
                }

                if( row.hasOwnProperty("cluster")){
                    variacaoRow.push(row.cluster);
                }

                this.props.periodos.map( (periodo, index) =>{
                    let valor = this.getVariacao( row , periodo );

                    if( valor == 0 ){
                        valor = '-';
                    }

                    variacaoRow.push(valor);
                });
                variacaoRow.push('-');
                rows.push(variacaoRow);
            }
        });

        return rows;
    }

    downloadExcel = async (e)=>{
        e.preventDefault();
        let rows = this.getBodyRows();
        let headers = this.getHeadersColumns();


        try{

            this.props.setLoading(true);

            const data = {
                headers: headers,
                rows: rows
            };


            let response = await axios.post( route('web.download.excel.relatorio.acompanhamento'), data ,{
                responseType: 'blob'
            } );

            let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

            let link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);

            link.download = 'relatorio_acompanhamento.xlsx';

            link.click();

        }catch(e){
            console.log(e);
        }finally{
            this.props.setLoading(false);
        }

    }


    render () {

        let headersColumns = this.getHeadersColumns();

        const estados = {
            'AC': 'Acre',
            'AL': 'Alagoas',
            'AP': 'Amapá',
            'AM': 'Amazonas',
            'BA': 'Bahia',
            'CE': 'Ceará',
            'DF': 'Distrito Federal',
            'ES': 'Espírito Santo',
            'GO': 'Goiás',
            'MA': 'Maranhão',
            'MT': 'Mato Grosso',
            'MS': 'Mato Grosso do Sul',
            'MG': 'Minas Gerais',
            'PA': 'Pará',
            'PB': 'Paraíba',
            'PR': 'Paraná',
            'PE': 'Pernambuco',
            'PI': 'Piauí',
            'RJ': 'Rio de Janeiro',
            'RN': 'Rio Grande do Norte',
            'RS': 'Rio Grande do Sul',
            'RO': 'Rondônia',
            'RR': 'Roraima',
            'SC': 'Santa Catarina',
            'SP': 'São Paulo',
            'SE': 'Sergipe',
            'TO': 'Tocantins'
        };


        return (
            <>
                <div>

                <p className="alert alert-info font-weight-bold">
                    <i className="fa fa-info-circle"></i> &nbsp;
                    Resultados de VGV são apresentados em mil R$.

                </p>
                <button onClick={this.downloadExcel} className="btn btn-success">
                    <i className='fa fa-file-excel-o'></i> &nbsp;
                    Download Excel
                </button>
                </div>
                <hr />
                <table className='table table-striped acompanhamento' style={{textAlign: "center"}}>
                    <thead>
                        <tr style={{backgroundColor: "#5B7537", color: "#ffffff", textAlign: 'center', fontWeight: 'bold'}}>

                            {headersColumns.map((column, index)=>{
                                return(
                                    <th key={index} style={{backgroundColor: "#5B7537", color: "#ffffff", textAlign: 'center', fontWeight: 'bold'}}>{column}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.rows.map( (row, index ) =>{
                            let soma = 0;

                            if(detalhesSemSoma.includes(row.detalhe)){
                                soma = '-';
                            }

                            return(
                                <React.Fragment key={index}>
                                    <tr >
                                        <th>{this.props.detalhesLabel[row.detalhe]}</th>
                                        {row.hasOwnProperty("tipo") && <th>{row.tipo}</th>}
                                        {row.hasOwnProperty("padrao") && <th>{row.padrao}</th>}
                                        {row.hasOwnProperty("ano_lancamento") && <th>{row.ano_lancamento}</th>}
                                        {row.hasOwnProperty("estagio") && <th>{row.estagio}</th>}
                                        {row.hasOwnProperty("incorporadora") && <th>{row.incorporadora}</th>}
                                        {row.hasOwnProperty("tipologia") && <th>{row.tipologia}</th>}
                                        {row.hasOwnProperty("cidade") && <th>{row.cidade}</th>}
                                        {row.hasOwnProperty("bairro") && <th>{row.bairro}</th>}
                                        {row.hasOwnProperty("estado") && <th>{estados[row.estado]}</th>}
                                        {row.hasOwnProperty("cluster") && <th>{row.cluster}</th>}
                                        {this.props.periodos.map( (periodo, index) =>{

                                            soma += row[periodo];

                                            if( row.detalhe == 'ivv'){
                                                return(
                                                    <td key={index} style={{borderColor: "#E3E3E3"}}s>
                                                        {this.formatNumberAsPercentage(row[periodo])}%
                                                    </td>
                                                )
                                            }
                                            return(
                                                <td key={index} style={{borderColor: "#E3E3E3"}}>
                                                    {this.formatNumber(row[periodo])}
                                                </td>
                                            )
                                        })}
                                        <td>
                                            { !detalhesSemSoma.includes(row.detalhe) && this.formatNumber(soma) }
                                            { detalhesSemSoma.includes(row.detalhe) && '-' }

                                        </td>
                                    </tr>
                                    {this.props.variacao !== '' && this.props.variacao != undefined && <>
                                        <tr>
                                            <th>Variação - {this.props.detalhesLabel[row.detalhe]}</th>
                                            {row.hasOwnProperty("tipo") && <th>{row.tipo}</th>}
                                            {row.hasOwnProperty("padrao") && <th>{row.padrao}</th>}
                                            {row.hasOwnProperty("ano_lancamento") && <th>{row.ano_lancamento}</th>}
                                            {row.hasOwnProperty("estagio") && <th>{row.estagio}</th>}
                                            {row.hasOwnProperty("incorporadora") && <th>{row.incorporadora}</th>}
                                            {row.hasOwnProperty("tipologia") && <th>{row.tipologia}</th>}
                                            {row.hasOwnProperty("cidade") && <th>{row.cidade}</th>}
                                            {row.hasOwnProperty("bairro") && <th>{row.bairro}</th>}
                                            {row.hasOwnProperty("cluster") && <th>{row.cluster}</th>}
                                            {this.props.periodos.map( (periodo, index) =>{

                                                let valor = this.getVariacao( row , periodo );
                                                if( valor == 0 ){
                                                    valor = '-';
                                                }else{
                                                    valor = this.formatNumberAsPercentage( valor ) + '%';
                                                }
                                                return(
                                                    <td key={index} style={{borderColor: "#E3E3E3"}}>
                                                        {valor < 0 && <i className="fas fa-angle-down" style={{color: "red"}}></i>}
                                                        {valor > 0 && <i className="fas fa-angle-up" style={{color: "green"}}></i>}
                                                        &nbsp; {valor}
                                                    </td>
                                                )
                                            })}
                                            <td> - </td>
                                        </tr>
                                    </>}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>

                <hr />

                <button onClick={this.downloadExcel} className="btn btn-success">
                    <i className='fa fa-file-excel-o'></i> &nbsp;
                    Download Excel
                </button>

            </>


        );
    }

    getPeriodoAnterior = ( periodo ) => {
        debugger;

        return this.props.periodos_variacao[this.props.periodos.indexOf(periodo)];

    }

    getVariacao = ( row, periodo ) => {


        let variacao = 0;
        let periodoAnterior = this.getPeriodoAnterior( periodo );

        let valorAtual = row[periodo] || 0;
        let valorAnterior = row[periodoAnterior] || 0;

        if( valorAtual == 0 && valorAnterior == 0){
            return 0;
        }

        if( valorAnterior == 0){
            return 100;
        }

        variacao = (valorAtual / valorAnterior - 1 ) * 100;

        if( isNaN(variacao)){
            return 0;
        }

        return variacao;
    }
}


export default TableRelatorioAcompanhamento;
